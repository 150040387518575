<template>
  <div id="home">
    <!-- Start the Home title section -->
    <div class="Home">
      <div class="blur"><div></div></div>
      <TitlePage
        :showButton="true"
        :btnTxt="btnText"
        class="home-title"
        :header="headerTitle"
        :description="description"
        @clicked="handleAboutUs"
      />
    </div>

    <div class="checkup_package">
      <div>
        <h1>Health Checkup Packages</h1>
        <p>We offer a series of health checkup services.</p>
      </div>
    </div>
    <div class="slide1">
      <div class="innerItem">
        <HomeSlide1 />
        <router-link to="/checkups"
          ><button class="Slide1-btn">
            More About Checkups Packages
          </button></router-link
        >
      </div>
    </div>
    <div class="service_bar">
      <div>
        <h1>Our Services</h1>
        <p>We work around the clock to provide services with Love and Care.</p>
      </div>
    </div>

    <div class="slide1">
      <div class="innerItem">
        <HomeSlide2 />
        <router-link to="/services"
          ><button class="Slide1-btn">
            More About Our Services
          </button></router-link
        >
      </div>
    </div>
    <div class="our_facilities">
      <div>
        <h1>Our Facilities</h1>
        <p>
          Our facilities enable us to serve you back to a healthy condition.
        </p>
      </div>
    </div>

    <div class="slide1">
      <div class="innerItem">
        <HomeSlide3 />
        <router-link to="/facilities"
          ><button class="Slide1-btn">
            More About Our Facilities
          </button></router-link
        >
      </div>
    </div>
    <div class="contact">
      <div>
        <h1>Contact Us</h1>
        <p>
          We are always delighted to hear from you, if you would like to reach
          out to us, we are here to listen to what you have to say,please feel
          free to contact us.
        </p>
      </div>
      <router-link to="/contact"
        ><button class="about-btn">Contact Us</button></router-link
      >
    </div>
    <Footer />
  </div>
</template>

<script>
import TitlePage from "../components/layouts/TitlePage";
import HomeSlide1 from "../components/HomeSlide1";
import HomeSlide2 from "../components/HomeSlide2";
import HomeSlide3 from "../components/HomeSlide3";
import Footer from "../components/Footer";

export default {
  name: "Home",
  components: {
    TitlePage,
    HomeSlide1,
    HomeSlide2,
    HomeSlide3,
    Footer,
  },
  data() {
    return {
      headerTitle: "Royal Hospitals",
      description:
        "We believe in healing the world one patient at a time with love and care.",
      btnText: "About Us",
    };
  },
  methods: {
    handleAboutUs() {
      this.$router.push("/about");
    },
  },
};
</script>

<style scoped>
#home {
  color: #fff;
  width: 100%;
  height: 100vh;
}

.Slide1-btn {
  width: 240px;
  height: 45px;
  background-color: transparent;
  border: 1px Solid #ce515c;
  color: #ce515c;
}
.Slide1-btn:hover {
  background-color: #ce515c;
  color: #fff;
}
.about-btn {
  width: 100px;
  height: 40px;
  background-color: #ce515c;
  border: unset;
  color: #fff;
}
button {
  margin-top: 2%;
  margin-bottom: 2%;
  transition: 200ms;
  transition-timing-function: ease-in-out;
}
button:hover {
  opacity: 0.9;
}
.Home {
  contain: content;
}
.blur {
  position: absolute;
  background-image: url("../assets/images/outside-shoot-12.jpg");
  background-position: center;
  background-size: cover;
  filter: blur(0px);
  -webkit-filter: blur(4px);
  width: 100%;
  height: 100%;
}
.blur div {
  background-color: rgba(59, 77, 177, 0.5);
}
.home-title {
  position: relative;
  height: 70vh;
  background-color: rgba(59, 77, 177, 0.5);
  display: grid;
  place-items: center;
}
.contact {
  background-color: #ce515c;
  /* height: 25vh; */
  width: 100%;
  display: grid;
  place-items: center;
  padding:0px 20px 20px 20px;
}
.contact button {
  width: 100px;
  height: 40px;
  background-color: #ffffff;
  border: unset;
  color: #3b4db1;
}
.checkup_package {
  background-color: #485ac2;
  height: 25vh;
  width: 100%;
  display: grid;
  place-items: center;
}
.service_bar,
.our_facilities {
  background-color: #3b4db1;
  height: 25vh;
  width: 100%;
  display: grid;
  place-items: center;
}
.slide1 {
  width: 100%;
  display: grid;
  place-items: center;
}
.slide1 .innerItem {
  padding-top: 10%;
  height: 100%;
  width: 90%;
  background-color: #f4fafa;
}
</style>