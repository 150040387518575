<template>
  <div class="container">
    <div id="myCarousel2" class="carousel slide" data-ride="carousel">
      <!-- Indicators -->
      <ol class="carousel-indicators">
        <li data-target="#myCarousel2" data-slide-to="0" class="active"></li>
        <li data-target="#myCarousel2" data-slide-to="1"></li>
        <li data-target="#myCarousel2" data-slide-to="2"></li>
        <li data-target="#myCarousel2" data-slide-to="3"></li>
      </ol>

      <!-- Wrapper for slides -->
      <div class="carousel-inner">
        <div class="item active">
          <div class="table">
            <div class="Txt">
              <h2>Medical Specialist Services</h2>
              <p>
                Cardiology, Pediatrics, Obstetrician and Gynecology,
                Echo-cardiography, Advanced Obstetric Ultrasound, and
                Infertility Services.
              </p>
            </div>
            <div class="Image">
              <img src="../assets/images/royal hospitals indoor shoot-2.webp" />
            </div>
          </div>
        </div>
        <!-- div Seperation -->

        <div class="item">
          <div class="table">
            <div class="Txt">
              <h2>Pharmacy</h2>
              <p>
                Wholesale and Retail of Pharmaceuticals, Medical Devices and
                Cosmetics.
              </p>
            </div>
            <div class="Image">
              <img
                src="../assets/images/royal hospitals indoor shoot-298.webp"
              />
            </div>
          </div>
        </div>

        <!-- div Seperation -->

        <div class="item">
          <div class="table">
            <div class="Txt">
              <h2>Laboratory Service</h2>
              <p>
                Clinical pathology tests to aid in the diagnosis, treatment, and
                prevention of disease.
              </p>
            </div>
            <div class="Image">
              <img
                src="../assets/images/royal hospitals indoor shoot-236.webp"
              />
            </div>
          </div>
        </div>

        <!-- div Seperation -->

        <div class="item">
          <div class="table">
            <div class="Txt">
              <h2>Telemedicine Services</h2>
              <p>
                Telemedicine Services Remote diagnosis and treatment of patients
                by means of telecommunications technology.
              </p>
            </div>
            <div class="Image">
              <img
                src="../assets/images/royal hospitals indoor shoot-264.webp"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- <a class="left carousel-control" href="#myCarousel2" data-slide="prev">
        <span class="glyphicon glyphicon-chevron-left"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="right carousel-control" href="#myCarousel2" data-slide="next">
        <span class="glyphicon glyphicon-chevron-right"></span>
        <span class="sr-only">Next</span>
      </a> -->
    </div>
    <!-- Left and right controls -->
  </div>
</template>

<script>
export default {
  name: "HomeSlide2",
};
</script>

<style scoped>
#myCarousel {
  height: inherit;
  padding-bottom: 50px;
}
.carousel-control {
  background-image: none;
  background-repeat: repeat-x;
  filter: none;
}

.Txt {
  width: 100%;
  height: 350px;
  padding: 2%;
  padding-top: 10%;
  text-align: left;
}
.Txt p {
  margin-top: 8%;
  color: #2e3c8b;
  line-height: 30px;
  font-size: 18px;
  font-weight: 500;
  font-family: "Darker Grotesque";
}
.Txt h2 {
  font-family: "Darker Grotesque";
  color: #ce515c;
}
.Image {
  width: 100%;
  height: 100%;
}
.Image img {
  width: 100%;
  height: 100%;
}
.table {
  display: flex;
  width: 100%;
}
.glyphicon {
  color: #002e69;
  font-weight: 500;
}
.carousel-inner {
  width: 80%;
  margin: auto;
  height: 100%;
}
.carousel-indicators li {
  width: 5px;
  height: 5px;
  margin: 5px;
  text-indent: 0;
  border-radius: 50%;
  background-color: #002e69;
  transition: 200ms;
  border: 2px solid #002e69;
  transition-timing-function: ease-in-out;
}
.carousel-indicators .active {
  width: 8px;
  height: 8px;
  margin: 5px;
  background-color: #ffffff;
}
@media screen and (max-width: 993px) {
  .table {
    display: block;
  }
}
</style>