<template>
  <div class="container">
    <div id="myCarousel-3" class="carousel slide" data-ride="carousel">
      <!-- Indicators -->
      <ol class="carousel-indicators">
        <li data-target="#myCarousel-3" data-slide-to="0" class="active"></li>
        <li data-target="#myCarousel-3" data-slide-to="1"></li>
        <li data-target="#myCarousel-3" data-slide-to="2"></li>
      </ol>

      <!-- Wrapper for slides -->
      <div class="carousel-inner">
        <div class="item active">
          <div class="table">
            <div class="Image">
              <img
                src="../assets/images/royal hospitals dr glory shoot-79.webp"
              />
            </div>
            <div class="Txt">
              <h2>Wards</h2>
              <p>Our wards are designed to be comfortable for patients.</p>
            </div>
          </div>
        </div>
        <!-- div Seperation -->

        <div class="item">
          <div class="table">
            <div class="Image">
              <img
                src="../assets/images/royal hospitals indoor shoot-32.webp"
              />
            </div>
            <div class="Txt">
              <h2>Consultation Rooms</h2>
              <p>The Support You Need</p>
            </div>
          </div>
        </div>

        <!-- div Seperation -->

        <div class="item">
          <div class="table">
            <div class="Image">
              <img
                src="../assets/images/royal hospitals indoor shoot-34.webp"
              />
            </div>
            <div class="Txt">
              <h2>Theatre</h2>
              <p>State-of-the-art technology and a patient-centered design.</p>
            </div>
          </div>
        </div>
      </div>
      <!-- <a class="left carousel-control" href="#myCarousel-3" data-slide="prev">
        <span class="glyphicon glyphicon-chevron-left"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="right carousel-control" href="#myCarousel-3" data-slide="next">
        <span class="glyphicon glyphicon-chevron-right"></span>
        <span class="sr-only">Next</span>
      </a> -->
    </div>
    <!-- Left and right controls -->
  </div>
</template>

<script>
export default {
  name: "HomeSlide3",
};
</script>

<style scoped>
#myCarousel {
  height: inherit;
  padding-bottom: 50px;
}
.carousel-control {
  background-image: none;
  background-repeat: repeat-x;
  filter: none;
}

.Txt {
  width: 100%;
  height: 300px;
  padding: 2%;
  padding-top: 10%;
  text-align: left;
}
.Txt p {
  margin-top: 8%;
  color: #2e3c8b;
  line-height: 30px;
  font-size: 18px;
  font-weight: 500;
  font-family: "Darker Grotesque";
}
.Txt h2 {
  font-family: "Darker Grotesque";
  color: #ce515c;
}
.Image {
  width: 100%;
  height: 100%;
}
.Image img {
  width: 100%;
  height: 100%;
}
.table {
  display: flex;
  width: 100%;
}
.glyphicon {
  color: #002e69;
  font-weight: 500;
}
.carousel-inner {
  width: 80%;
  margin: auto;
  height: 100%;
}
.carousel-indicators li {
  width: 5px;
  height: 5px;
  margin: 5px;
  text-indent: 0;
  border-radius: 50%;
  background-color: #002e69;
  transition: 200ms;
  border: 2px solid #002e69;
  transition-timing-function: ease-in-out;
}
.carousel-indicators .active {
  width: 8px;
  height: 8px;
  margin: 5px;
  background-color: #ffffff;
}
@media screen and (max-width: 993px) {
  .table {
    display: block;
  }
}
</style>